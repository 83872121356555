.divider-top {
  border-top: 1px solid var(--color-border_200);
  padding-top: 20px !important;
  margin-top: 20px;
}
.divider-bottom {
  border-bottom: 1px solid var(--color-border_200);
  padding-bottom: 20px !important;
  margin-bottom: 20px;
}
.module-header {
  padding: 30px 40px 0;
}
.module-body {
  position: relative;
  padding: 30px 40px;
}
.module-body.contrast {
  border-top: 1px solid var(--color-border_200);
  background: var(--color-background);
  min-height: calc(100vh - 170px);
}

#site-inner #collapse-sidenav {
  position: fixed;
  bottom: 49px;
  left: 0px;
  top: unset;
  height: 40px;
  width: 40px;
  display: inline-flex;
  background: var(--color-background);
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border_200);
  border-left: none;
  svg {
    fill: var(--color-text);
  }
}

.auth-form label {
  display: none;
}
// SideNav Styles
#sidenav {
  border-right: 1px solid var(--color-border_200);

  .menu-header {
    padding: 30px 40px 30px;
  }

  h3 {
    font-size: 18px;
    margin: 0 0 5px;
  }

  .auth-email,
  .auth-role {
    font-size: 12px;
  }
  ul {
    padding: 0;
  }

  .btn-logout {
    padding: 15px;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--color-text);
    border-top: 1px solid var(--color-border_200);
  }
  li {
    font-size: 15px;
    padding: 10px 40px;
  }
  a {
    opacity: 0.8;
    transition: all ease 0.3s;
  }
  a.current {
    font-weight: 700;
    opacity: 1;
  }

  .color-button {
    background: none;
    border: none;
    width: 100%;
    outline: none;
    color: var(--color-text);
    border-top: 1px solid var(--color-border_200);
    padding-top: 20px;
    padding-left: 35px;
    svg {
      fill: var(--color-text);
      height: 18px;
      margin-right: 10px;
    }
  }
}
// Module Header Styles
h1 {
  font-size: 28px;
  margin: 0px;
}
.sm-text {
  font-size: 14;
}
.nav-filters {
  margin: 25px 0 0;

  button {
    color: var(--color-text);
    font-size: 14px;
    opacity: 0.33;
    padding: 0 0 8px;
    transition: all ease 0.3s;
    margin-right: 30px;
    border-bottom: 2px solid transparent;
    &.active,
    &:hover {
      opacity: 1;
    }

    &.active {
      border-color: var(--color-text);
    }
  }
}

.data-header {
  position: sticky;
  top: -1px;
  background: var(--color-bg_card);
  padding: 10px 0 8px;
  border: 1px solid var(--color-border_200);
  z-index: 10;
  .label {
    text-transform: uppercase;
    font-weight: 700;
    font-family: var(--font-oblique);
    font-size: 14px;
    padding: 0 15px;
  }
}

.data-list {
  padding: 0;
  margin: 0;
  border: 1px solid var(--color-border_200);
  border-top: none;
  li {
    background: var(--color-background);
    border-bottom: 1px solid var(--color-border_200);
    padding: 12px;
    font-size: 15px;

    span {
      display: block;
    }

    &:last-of-type {
      border: none;
    }
  }
}
