/* Custom font declaration */
/* Added to document in `src/pages/_app.tsx`  */
@font-face {
  font-family: 'AGOblique';
  src: url('/fonts/AvantGardeOblique/ITCAvantGarde-MdObl.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'AGOblique';
  src: url('/fonts/AvantGardeOblique/ITCAvantGarde-BoldObl.otf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}
